import {addLocale, LocaleData, t as ttagFunc, ngettext, msgid, useLocale} from "ttag";

declare global {
    interface Window { translations: Record<string, LocaleData> }
}

let t = ttagFunc

// This style is inspired by lolcalize in canvas
if(document.documentElement.lang === 'lol') {
    function lolcalize(str: string) {
        let ret = ''
        for(let i = 0; i < str.length; i++) {
            ret += (i % 2 === 0 ? str[i].toUpperCase() : str[i].toLowerCase());
        }
        const finalStr = ret.replaceAll(/\.( |\z)/g, "!!?!").replaceAll(/\A(\w+)\z/g, "$1!");
        if(finalStr.length > 2) {
            return finalStr + " LOL!"
        }
        return finalStr
    }

    t = function(strings, ...expr) {
        const lolcalizedStrings = strings.map(lolcalize);
        return ttagFunc(Object.assign([], lolcalizedStrings, {raw: strings.raw}) as TemplateStringsArray, ...expr)
    }
}

function initI18n() {
    if(window.translations && window.translations[document.documentElement.lang].headers) {
        addLocale(document.documentElement.lang, window.translations[document.documentElement.lang]);
        // eslint-disable-next-line react-hooks/rules-of-hooks -- This is from ttag, not a react hook
        useLocale(document.documentElement.lang);
    }
}

export { t, ngettext, msgid, initI18n }